<template>
  <gf-base>
    <gf-card title="Products" subtitle="List of Products">
      <template #toolbar>
        <gf-button type="primary" @click="newProduct">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" cx="9" cy="15" r="6"/>
                  <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          New Record
        </gf-button>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="0" label="Inactive"></el-option>
          <el-option :value="1" label="Active"></el-option>
        </el-select>
      </template>
      <el-dialog title="Set to Active" :visible.sync="activeModalVisible" append-to-body v-loading="activeModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-primary svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                  <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
                  <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to set the status of {{ this.selected.length }} product to Active. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="activeModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="setActive">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Set to Inactive" :visible.sync="inactiveModalVisible" append-to-body v-loading="inactiveModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-warning svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                  <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
                  <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to set the status of {{ this.selected.length }} products to Inactive. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="inactiveModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="warning" @click="setInactive">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Delete Companies" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                  <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                  <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to delete {{ this.selected.length }} products. This action cannot be undone. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="deleteModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="remove">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
        </template>
      </el-dialog>
      <gf-table :data="products" :column-filter="columnFilter" :selection-command="selectionCommand" @command="command" @row-click="readProduct" v-loading="loading">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column v-if="columnFilter[0].show" label="Name" min-width="150" prop="name">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Product Type" min-width="150" prop="product_type.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Brand" min-width="150" prop="brand.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Available" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.stocks }} in {{ scope.row.variant_count }} {{ scope.row.variant_count > 1 ? 'variants' : 'variant' }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Status" min-width="100">
          <template slot-scope="slot">
            <gf-label inline pill light type="success" v-if="slot.row.is_active">Active</gf-label>
            <gf-label inline pill lgiht type="dark" v-else>Inactive</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Last Updated" min-width="150" prop="updated_at">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getProducts"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import ProductService from '@/services/v1/Product'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      columnFilter: [
        { label: 'Name', show: true },
        { label: 'Supplier', show: true },
        { label: 'Product Type', show: true },
        { label: 'Brand', show: true },
        { label: 'Available', show: true },
        { label: 'Status', show: true },
        { label: 'Last Updated', show: true }
      ],
      selectionCommand: [
        { label: 'Set Active', command: 'active' },
        { label: 'Set Inactive', command: 'inactive' },
        { label: 'Delete', command: 'delete' }
      ],

      search: '',
      status: null,
      selected: [],
      filter: {},

      loading: false,
      activeModalVisible: false,
      activeModalLoading: false,
      inactiveModalVisible: false,
      inactiveModalLoading: false,
      deleteModalVisible: false,
      deleteModalLoading: false,

      // main
      products: [],

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1

      this.getProducts()
    }, 400)
  },
  methods: {
    // navigation
    newProduct () {
      this.$router.push({ name: 'product-create' })
    },
    readProduct (row) {
      this.$router.push({ name: 'product-detail', params: { id: row.id } })
    },
    async setActive () {
      try {
        this.activeModalLoading = true
        const productId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          productId.push(sl.id)
        }

        const pdService = new ProductService()
        await pdService.setActive(productId)
        this.$message.success(`${productId.length} products were successfully set to active`)

        this.activeModalVisible = false
        this.getProducts()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.activeModalLoading = false
      }
    },
    async setInactive () {
      try {
        this.inactiveModalLoading = true
        const productId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          productId.push(sl.id)
        }

        const pdService = new ProductService()
        await pdService.setInactive(productId)
        this.$message.success(`${productId.length} products were successfully set to active`)

        this.inactiveModalVisible = false
        this.getProducts()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.inactiveModalLoading = false
      }
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const productId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          productId.push(sl.id)
        }

        const pdService = new ProductService()
        await pdService.delMultiple(productId)
        this.$message.success(`${productId.length} products were successfully deleted`)

        this.deleteModalVisible = false
        this.getProducts()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },
    command (event) {
      this.selected = event.selection

      if (event.cmd === 'active') {
        this.activeModalVisible = true
        return
      }

      if (event.cmd === 'inactive') {
        this.inactiveModalVisible = true
        return
      }

      if (event.cmd === 'delete') {
        this.deleteModalVisible = true
      }
    },

    // filter
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['pd.is_active'] = { '=': this.status }
        this.getProducts()
        return
      }

      delete this.filter['pd.is_active']
      this.getProducts()
    },

    // fetch
    async getProducts () {
      try {
        this.loading = true
        const pdService = new ProductService()
        const response = await pdService.list(this.pagination.page, null, this.filter, null)
        this.products = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Products' }
    ])

    this.getProducts()
  }
}
</script>
